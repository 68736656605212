<template>
  <div>
    <div class="tfw-candidate-profile" v-if="isTFW">
      <CandidateProfileTFW />
    </div>
    <div class="candidate-profile" v-else>
      <CandidateProfileTalentfind />
    </div>
  </div>
</template>

<script>
import CandidateProfileTalentfind from "@/pages/CandidateProfile/CandidateProfileTalentfind.vue";
import CandidateProfileTFW from "@/pages/TFW/CandidateProfileTFW.vue";
import { mapGetters } from "vuex";
export default {
  name: "CandidateProfile",
  components: {
    CandidateProfileTalentfind,
    CandidateProfileTFW,
  },
  computed: {
    ...mapGetters(["isSuperCustomer"]),
    isTFW() {
      return this.isSuperCustomer;
    },
  },
};
</script>