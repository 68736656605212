
import { mapGetters, mapActions } from "vuex";
import Stepper from "@/components/reusable/bars/stepper.vue";
import PersonalInfo from "@/containers/CandidateProfile/PersonalInfoTalentFind";
import ResumeUpload from "@/containers/CandidateProfile/ResumeUpload";
import MultipleFileUpload from "@/containers/CandidateProfile/MultipleFileUploadTalentFind";
import ContactInfo from "@/containers/CandidateProfile/ContactInfoTalentFind";
import ProfessionalInfo from "@/containers/CandidateProfile/ProfessionalInfoTalentFind";
import AvailabilityInfo from "@/containers/CandidateProfile/AvailabilityInfoTalentFind";
import LanguageInfo from "@/containers/CandidateProfile/LanguageInfoTalentFind";
import NonMedicalInfoTalentFind from "@/containers/CandidateProfile/NonMedicalInfoTalentFind";
import SkillInfo from "@/containers/CandidateProfile/SkillInfoTalentfind";
import PQRMatchInfo from "@/containers/CandidateProfile/PQRMatch";
import GDPRStatement from "@/containers/CandidateProfile/T&CandGDPRStatement";
import LanguageTestScore from "@/containers/CandidateProfile/LanguageTestScore";
import LanguageQualification from '@/containers/CandidateProfile/LanguageQualification';
import CompetencyExam from '@/containers/CandidateProfile/CompetencyExam';
import { BASE_URL } from "@/service_urls";

export default {
  components: {
    Stepper,
    PersonalInfo,
    ResumeUpload,
    MultipleFileUpload,
    ContactInfo,
    ProfessionalInfo,
    AvailabilityInfo,
    SkillInfo,
    PQRMatchInfo,
    GDPRStatement,
    LanguageInfo,
    NonMedicalInfoTalentFind,
    LanguageTestScore,
    LanguageQualification,
    CompetencyExam,
  },
  data() {
    return {
      isMobile: false,
      block: this.$store.state.candidate.block,
      steps: null,
      profilePhoto: {},
      stepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Professional",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Language and Non Medical Qualifications",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Documents",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Availability",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Contacts",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Personal",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Privacy & Terms",
            tooltipPlacement: "bottom-start",
          },
        ],
      },
      sectionNamesCandidateProfile: [
        {
          id: 1,
          title: "Professional Info",
          icon: "fa-solid fa-circle-info",
        },
        {
          id: 2,
          title: "Language and Non Medical Qualifications",
          icon: "fa-solid fa-user-graduate",
        },
        {
          id: 3,
          title: "Documents",
          icon: "fa-solid fa-file",
        },
        {
          id: 4,
          title: "Work Preference",
          icon: "fa-solid fa-id-card",
        },
        {
          id: 5,
          title: "Contact Information",
          icon: "fa-solid fa-phone",
        },
        {
          id: 6,
          title: "Personal Information",
          icon: "fa-solid fa-user",
        },
        {
          id: 7,
          title: "Privacy & Terms",
          icon: "fa-solid fa-handshake",
        },
      ],
      nonClinicStepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Professional",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Language",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Documents",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Availability",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Contacts",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Personal",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Privacy & Terms",
            tooltipPlacement: "bottom-start",
          },
        ],
      },
      profile: {},
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getUploadedDocument",
      "getMultiFormEdited",
      "getCustomerId",
    ]),
    document() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument.length &&
        this.getUploadedDocument.map(
          ({ document_type_id, link_to_doc, document_name, document_type }) => {
            return {
              link: link_to_doc,
              type: document_type_id,
              name: document_name,
            };
          }
        )
      );
    },
    getProfilePhoto() {
      const profilePic =
        this.document &&
        this.document.length &&
        this.document
          .filter((e) => e.type === 2)
          .map((data) => {
            return data.link.replace("/api/v1/", "");
          });
      return profilePic && profilePic.length && `${BASE_URL}/${profilePic}`;
    },

    isfetch() {
      const profile = this.candidateProfile;
      if (this.candidateProfile != null) {
        this.profile = {
          ...profile,
          gender:
            profile.gender != null
              ? { code: profile.gender.gender_id, label: profile.gender.gender }
              : null,
          nationality: profile.nationality
            ? {
              nationality: profile.nationality.nationality,
              label: profile.nationality.country_name,
              code: profile.nationality.country_id,
            }
            : null,
          dob: profile.dob ? new Date(profile.dob) : null,
          location: profile.location
            ? {
              label: profile.location.comments,
              code: profile.location.location_id,
              country: profile.location.country
                ? profile.location.country.country_name
                : null,
            }
            : null,
          candidate_type: profile.candidate_type
            ? {
              label: profile.candidate_type.candidate_type,
              code: profile.candidate_type.candidate_type_id,
            }
            : null,
          speciality: profile.speciality
            ? {
              label: profile.speciality.speciality,
              code: profile.speciality.speciality_id,
            }
            : null,
          sub_speciality: profile.sub_speciality
            ? {
              label: profile.sub_speciality.sub_speciality,
              code: profile.sub_speciality.sub_speciality_id,
            }
            : null,
          current_level: profile.sub_speciality
            ? {
              label: profile.sub_speciality.sub_speciality,
              code: profile.sub_speciality.sub_speciality_id,
            }
            : null,
          main_qualification: profile.main_qualification
            ? {
              label: profile.main_qualification.qualification,
              code: profile.main_qualification.qualification_id,
            }
            : [],
          candidate_level: profile.candidate_level
            ? {
              label: profile.candidate_level.candidate_level,
              code: profile.candidate_level.level_id,
            }
            : null,
          preferred_locations: profile.preferred_locations
            ? profile.preferred_locations.map((l) => {
              return {
                label: l.location.location,
                code: l.preferred_location_id,
              };
            })
            : null,
        };
        return Object.keys(this.profile).length > 0;
      }
      return false;
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isNonClinicAdmin() {
      return this.profile?.candidate_type_id == 6 ? true : false;
    },
  },

  methods: {
    ...mapActions([
      "initRegisterFetchOptions",
      "initFetchOptions",
      "fetchUploadedDocument",
      "getCandidate",
      "getUserMe",
      "uploadPhoto",
      "uploadResume",
      "showToast",
      "fetchLicense",
      "fetchSkills",
      "fetchCandidateLanguage",
      "fetchQualification",
      "fetchspecialInterest",
      "fetchPQRLevels",
      "fetchCandidatePreferredRegionList",
      "fetchCandidatePreferredCountryList",
      "multiFormEdited",
      "fetchCandidateNonMedicalQuals",
      "fetchCandidateExperiences",
      "fetchPreferredDepartments",
      "fetchDepartments",
      "fetchNurseRegistrationType"
    ]),
    gotoStep(step) {
      this.multiFormEdited({ showError: false });
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
      } else {
        this.block = step;
        this.$store.dispatch("scrollTop");
      }
    },
    navigateToHome() {
      this.multiFormEdited({ showError: false });
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
      } else {
        this.$router.push("/home");
      }
    },
    reset() {
      this.block = 0;
    },
    goTo(event, obj) {
      this.multiFormEdited({ showError: false });
      let currentBlock = this.block;
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
        setTimeout(() => {
          obj.currentPosition = this.block;
        }, 200);
      } else {
        this.slideTo(event, obj);
      }
    },
    slideTo(event, obj) {
      this.block = event;
    },
    goToProfile() {
      this.$router.push({ path: "/home" });
    },
    photoUpload(payload) {
      this.profilePhoto = payload;
      this.$store.commit("SET_UPLOADED");
      this.upload(payload);
    },
    upload(payload) {
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
          }).then((res) => {
            if (res) {
              const { data } = res;
              const { is_version_doc_type, document_type_id } = data;
              if (is_version_doc_type === false || document_type_id === 1) {
                //Append Document
                this.$store.commit("APPEND_DOCUMENT", data);
              } else {
                //Override Document
                this.$store.commit("OVERRIDE_DOCUMENT", data);
              }
            }
          });
        } else
          this.uploadResume({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
          }).then((res) => {
            if (res) {
              const { data } = res;
              const { is_version_doc_type, document_type_id } = data;
              if (is_version_doc_type === false || document_type_id === 1) {
                //Append Document
                this.$store.commit("APPEND_DOCUMENT", data);
              } else {
                //Override Document
                this.$store.commit("OVERRIDE_DOCUMENT", data);
              }
            }
          });
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip-old");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
  },
  created() {
    this.$store.commit("RESET_MULTI_FORM_EDITED");
  },
  mounted() {
    if (this.$el.getElementsByClassName("d-block")?.[0]) {
      let el = this.$el.getElementsByClassName("d-block")[0];
      el.scrollIntoView({ block: "nearest" });
    }
    let appendAction;
    const id = this.$route.query ? this.$route.query.id : null;
    if (id) {
      appendAction = this.getCandidate({ customer: this.getCustomerId, id });
    } else {
      appendAction = this.getUserMe({ customer: this.getCustomerId });
    }
    Promise.all([appendAction]).then((res) => {
      this.initRegisterFetchOptions();
      this.initFetchOptions();
      this.fetchUploadedDocument();
      this.fetchLicense();
      this.fetchSkills();
      this.fetchCandidateLanguage();
      this.fetchQualification();
      this.fetchspecialInterest();
      this.fetchPQRLevels();
      this.fetchCandidatePreferredRegionList();
      this.fetchCandidatePreferredCountryList();
      this.fetchCandidateNonMedicalQuals();
      this.fetchCandidateExperiences();
      if (this.isNurse) {
        this.fetchDepartments(this.profile);
        this.fetchPreferredDepartments();
      }
      this.fetchNurseRegistrationType({ customer_id: this.getCustomerId });
    });
    let isMobile = window.matchMedia(
      "only screen and (max-width: 400px)"
    ).matches;
    this.isMobile = isMobile;
  },
  updated() {
    return this.$store.state.candidate.block;
  },
};